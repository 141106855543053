import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/layout/Container'
import ContentfulRichText from 'components/contentful-rich-text'

import s from './ArticleSliceText.scss'

const ArticleSliceText = ({ content, ...props }) => {
  const json = content || props.textBody.json
  return (
    <Container>
      <div className={s.articleSliceText}>
        <ContentfulRichText content={json} />
      </div>
    </Container>
  )
}

export default ArticleSliceText
