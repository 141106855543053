import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { TimelineLite } from 'gsap';

import Image from 'components/image';

import s from './ArticleAuthor.scss';

export default class ArticleAuthor extends PureComponent {

  static propTypes = {
    author: PropTypes.shape({
      image: PropTypes.object,
      name: PropTypes.string,
      title: PropTypes.string,
    }),
  }

  static defaultProps = {
    author: null,
  }

  componentDidMount() {
    this.animate();
  }

  animate = () => {
    const t = new TimelineLite();
    const contentItems = this.author.childNodes;

    t.addLabel('start');

    if (contentItems.length > 0) {
      t.staggerFromTo(contentItems, 1, { autoAlpha: 0, y: 40 }, { autoAlpha: 1, y: 0 }, 0.2, 'start');
    }
  }

  render() {
    const { name, title, image } = this.props.author;

    return (
      <div className={s.articleAuthor}>
        {image &&
        <div className={s.articleAuthor__imageWrap} ref={(el) => { this.image = el; }}>
          <div className={s.articleAuthor__image}>
            <Image
              src={_get(image, 'file.url')}
              alt={_get(image, 'description')}
              width={_get(image, 'file.details.image.width')}
              height={_get(image, 'file.details.image.height')}
            />
          </div>
        </div>
        }
        <div className={s.articleAuthor__caption} ref={(el) => { this.author = el; }}>
          <span className={s.articleAuthor__captionBy}>Written by {name}</span>
          <span className={s.articleAuthor__captionTitle}>{title}</span>
        </div>
      </div>
    );
  }
}
