import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import _get from 'lodash/get';
import LazyLoad from 'react-lazyload';

import Clickable from 'components/clickable';
import Container from 'components/layout/Container';
import ContentGrid from 'components/content-grid';
import ContentCard from 'components/content-grid/ContentCard';

import s from './PopularBlogArticle.scss';

const getSubTitle = (obj) => {
  const createdDate = _get(obj, 'createdDate');
  const subTitle = _get(obj, 'subTitle');

  return subTitle || createdDate;
}

export default () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <div className={s.popularBlogArticle}>
        <div className={s.popularBlogArticle__container}>
          <div className={s.popularBlogArticle__inner}>
            <h1 className={s.popularBlogArticle__heading}>{data.page.blogPopularListTitle}</h1>
            <ul className={s.popularBlogArticle__list}>
              {data.page.blogArticleList.map((card, i) => (
                <li className={s.popularBlogArticle__item} key={_get(card, 'title') + i}>
                  <LazyLoad once offset={500}>
                    <ContentCard
                      title={_get(card, 'title')}
                      subTitle={getSubTitle(card)}
                      bodyText={_get(card, 'bodyText')}
                      ctaUrl={_get(card, 'ctaUrl')}
                      cover={{
                        src: _get(card, 'heroImage.file.url'),
                        alt: _get(card, 'heroImage.description'),
                        width: _get(card, 'heroImage.file.details.image.width'),
                        height: _get(card, 'heroImage.file.details.image.height'),
                      }}
                    />
                  </LazyLoad>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )}
  />
);

const query = graphql`
  query PopularList {
    page: contentfulBlogPopularList {
      blogPopularListTitle,
      blogArticleList {
        title
        createdDate(formatString: "MMM DD, YYYY")
        ctaUrl: slug
        heroImage {
          ...image
        }
      }
    }
  }
`;
