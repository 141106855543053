import React from 'react';
import PropTypes from 'prop-types';
import kebabCaseIcon from 'utils/kebab-case-icon';
import { readableColor } from 'polished'
import Button from 'components/button';
import Container from 'components/layout/Container';

import s from './ArticleSliceBanner.scss';

const ArticleSliceBanner = props => {
  const image = props?.bannerImage?.file?.url
  const width = props?.bannerImage?.file?.details?.image?.width || 1
  const height = props?.bannerImage?.file?.details?.image?.height || 1
  const ratio = `${(1 / (width / height || 1)) * 100}%`

  const mobileImage = props?.bannerImageMobile?.file?.url || false

  const title = props.bannerTitle
  const buttonLink = props.bannerButtonLink
  const buttonText = props.bannerButtonText
  const buttonIcon = props.bannerButtonIcon
  const background = props.bannerBackgroundColor
  const isDark = readableColor(background || '#fff') === '#fff'

  return (
    <div className={s.articleSliceBanner}>
      <Container>
        <div className={s.articleSliceBanner__inner} style={{ background }}>
          {image && (
            <div className={s.articleSliceBanner__image}>
              <div style={{ paddingTop: ratio }}>
                <img src={image}/>
              </div>
            </div>
          )}
          <div className={s.articleSliceBanner__mobileImage}>
            {mobileImage && (
              <img src={mobileImage}/>
            )}
          </div>
          <div className={s.articleSliceBanner__content}>
            <div className={isDark
              ? s.articleSliceBanner__titleDark
              : s.articleSliceBanner__title
            }>
              {title}
            </div>
            {buttonText && (
              <Button
                to={buttonLink}
                color={isDark ? 'transparent' : 'red'}
                icon={kebabCaseIcon(buttonIcon)}
                className={isDark ? s.articleSliceBanner__darkButton : ''}
              >
                {buttonText}
              </Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ArticleSliceBanner;
