import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

import s from './SocialBar.scss';

const SocialBar = ({ text, link }) => (
  <div className={s.socialBar}>
    <div className={s.socialBar__main}>
      <h3 className={s.socialBar__heading}>share</h3>
      <div className={s.socialBar__links}>
        <a className={s.socialBar__link} href={`https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`} target="_blank" rel="noreferrer noopener" aria-label="Share on Facebook" title="Share on Facebook">
          <Icon id="social-facebook" className={s('socialBar__icon', 'facebook')} />
        </a>
        <a className={s.socialBar__link} href={`https://twitter.com/intent/tweet/?text=${encodeURIComponent(text)};url=${encodeURIComponent(link)}`} target="_blank" rel="noreferrer noopener" aria-label="Share on Twitter" title="Share on Twitter">
          <Icon id="social-twitter" className={s('socialBar__icon', 'twitter')} />
        </a>
        <a className={s.socialBar__link} href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(link)}&title=${encodeURIComponent(text)}&summary=${encodeURIComponent(text)}&source=${encodeURIComponent(link)}`} target="_blank" rel="noreferrer noopener" aria-label="Share on LinkedIn" title="Share on LinkedIn">
          <Icon id="social-linkedin" className={s('socialBar__icon', 'linkedin')} />
        </a>
        <a className={s.socialBar__link} href={`mailto:?subject=${encodeURIComponent(text)}&body=${encodeURIComponent(link)}`} target="_self" rel="noreferrer noopener" aria-label="Share on E-mail" title="Share on E-mail">
          <Icon id="social-email" className={s('socialBar__icon', 'email')} />
        </a>
      </div>
    </div>
  </div>
);

SocialBar.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
};

export default SocialBar;
