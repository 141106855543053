import { graphql } from 'gatsby';
import BlogArticle from 'routes/blog/BlogArticle';

export default BlogArticle;

export const query = graphql`
  query BlogArticle($slug: String!) {
    page: contentfulBlogArticle(slug: { eq: $slug }) {
      slug
      metaTitle: title
      metaDescription: heroHeading
      articleAuthor {
        name
        title
        image {
          ...image
        }
      }
      title
      heroHeading
      heroImage {
        ...image
      }
      heading
      createdDate(formatString: "MMM DD, YYYY")
      body {
        json
        childContentfulRichText {
          html
        }
      }
      slices {
        metaTitle
        type
        bannerTitle
        bannerImage {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        bannerImageMobile {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        bannerButtonText
        bannerButtonLink
        bannerButtonIcon
        bannerBackgroundColor
        quoteContent {
          internal {
            content
          }
        }
        quoteAuthorName
        quoteAuthorPosition
        textBody {
          json
          childContentfulRichText {
            html
          }
        }
      }
      category
    }
  }
`;
