import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import { withUIContext } from 'context/ui';
import HubspotForm from 'react-hubspot-form';
import Popup from 'components/popup';
import Checkmark from 'assets/svg/checkmark.svg';

import s from './NewsletterPopup.scss';
import './hbspt.css';

const POPUP_TIMER = 30000; // 30 seconds in milliseconds

const query = graphql`
query NewsletterPopup {
  newsletterPopup: contentfulNewsletterPopup {
    headline
    subheadline
    hasSubscribed
    buttonText
    backgroundColourHex
    sideImage {
      ...image
    }
  }
}
`;

class NewsletterPopup extends React.Component {

  static propTypes = {
    data: PropTypes.object,
    ui: PropTypes.object,
  }

  state = {
    isOpen: false,
    hasSubmit: false,
  }

  setCookie() {
    // Date 30 days from now
    const future = new Date();

    future.setDate(future.getDate() + 30);

    // Set a global cookie with that date
    const cookies = new Cookies();

    cookies.set('showNewsletterPopup', {
      path: '/',
      expires: Math.floor(future.getTime() / 1000), // Unix time in seconds rather than milliseconds
    });
  }

  componentDidMount() {
    setTimeout(() => {
      const cookies = new Cookies();

      cookies.remove('showNewsletterPopup');

      // If cookie isn't set or has expired
      if (!cookies.get('showNewsletterPopup')) {
        this.setState({
          isOpen: true,
        });

        this.setCookie();
      }

    }, POPUP_TIMER);
  }

  render() {
    const {
      data: {
        newsletterPopup,
      },
      ui: {
        isMobile,
      },
    } = this.props;

    return (
      <Popup
        open={this.state.isOpen}
        noPadding
        hasSubmit={this.state.hasSubmit}
        successElement={<div className={s('success')}><Checkmark /></div>}
      >
        <div className={s.flex}>
          <div className={s.left} style={{ backgroundColor: newsletterPopup.backgroundColourHex }}>
            { !isMobile &&
              <img src={newsletterPopup.sideImage.file.url} className={s.cropImage} alt="" />
            }
          </div>
          <div className={s.right}>
            <h2 className={s.headline}>{newsletterPopup.headline}</h2>
            <span className={s.subheadline}>{newsletterPopup.subheadline}</span>
            <div className={s.hubspotForm}>
              <HubspotForm
                portalId="5092845"
                cssClass="newsletter stacked"
                formId="8743bdf7-61ea-4d7e-be97-0145dd771885"
                onSubmit={() => {
                  this.setState({
                    hasSubmit: true,
                  });
                }}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

const queriedComponent = props => (
  <StaticQuery
    query={query}
    render={data => (
      <NewsletterPopup
        data={data}
        ui={props.ui}
      />
    )}
  />
);

queriedComponent.propTypes = {
  ui: PropTypes.object,
};

export default withUIContext(queriedComponent);
