import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withUIContext } from 'context/ui';
import _get from 'lodash/get';

import extractMeta from 'utils/extract-meta';
import SEO from 'components/seo';
import Container from 'components/layout/Container';
import ArticleIntro from 'components/article-intro';
import ArticleSlice from 'components/article-slice';
import SocialBar from 'components/social-bar';
import PopularBlogArticles from 'components/popular-blog-articles';
import NewsletterCta from 'components/newsletter-cta';
import NewsletterPopup from 'components/newsletter-popup';

import s from './BlogArticle.scss';

class BlogArticle extends PureComponent {

  state = {
    location: null,
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
    ui: PropTypes.object,
  }

  componentDidMount() {
    const { ui } = this.props;

    this.setState({ location: window.location.href });
    this.props.data.page.heroTheme && ui.setNavTheme(this.props.data.page.heroTheme);
  }

  componentWillUnmount() {
    this.props.ui.setNavTheme('dark');
  }

  render() {
    const { page } = this.props.data;
    const { pathname } = this.props.location;
    const { location } = this.state;

    return (
      <Fragment>
        <SEO {...extractMeta(page)} pathname={pathname} article />
        <NewsletterPopup />
        <article className={s.blogArticle}>
          <Container>
            <ArticleIntro
              title={page.title}
              category={page.category}
              createdDate={page.createdDate}
              author={page.articleAuthor}
              heroImage={{
                src: _get(page, 'heroImage.file.url'),
                alt: _get(page, 'heroImage.description'),
                width: _get(page, 'heroImage.file.details.image.width'),
                height: _get(page, 'heroImage.file.details.image.height'),
              }}
            />
          </Container>
          <section className={s.blogArticle__slice}>
            <ArticleSlice type='Text' content={page.body.json} />
          </section>
          {!!_get(page, 'slices.length') && page.slices.map(slice => (
            <section className={s.blogArticle__slice}>
              <ArticleSlice {...slice} />
            </section>
          ))}
        </article>
        <SocialBar text={page.title} link={location} />
        <PopularBlogArticles />
        <NewsletterCta />
      </Fragment>
    );
  }
}

export default withUIContext(BlogArticle);
