import React from 'react';
import PropTypes from 'prop-types';

import ArticleSliceQuote from './ArticleSliceQuote';
import ArticleSliceText from './ArticleSliceText';
import ArticleSliceBanner from './ArticleSliceBanner';

const ArticleSlice = ({type, ...props}) => {
  if(type === 'Quote') return <ArticleSliceQuote {...props} />;
  if(type === 'Text') return <ArticleSliceText {...props} />;
  if(type === 'Banner') return <ArticleSliceBanner {...props} />;
  return null;
};

export default ArticleSlice;
