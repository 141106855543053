import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/layout/Container'

import s from './ArticleSliceQuote.scss';

const ArticleSliceQuote = props => {
  const content = props.quoteContent.internal.content
  const authorName = props.quoteAuthorName
  const authorPosition = props.quoteAuthorPosition
  return (
    <div className={s.articleSliceQuote}>
      <div className={s.articleSliceQuote__inner}>
        <Container>
          <div className={s.articleSliceQuote__content}>
            {content}
          </div>
          <div className={s.articleSliceQuote__authorName}>
            {authorName}
          </div>
          <div className={s.articleSliceQuote__authorPosition}>
            {authorPosition}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ArticleSliceQuote;
