import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';

import Image from 'components/image';
import ArticleAuthor from 'components/article-author';

import s from './ArticleIntro.scss';

export default class ArticleIntro extends PureComponent {

  static propTypes = {
    title: PropTypes.string,
    category: PropTypes.string,
    createdDate: PropTypes.string,
    heroImage: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }

  static defaultProps = {
    title: null,
    category: null,
    createdDate: null,
  }

  componentDidMount() {
    this.animate();
  }

  animate = () => {
    const t = new TimelineLite();

    const contentItems = this.intro.childNodes;

    t.addLabel('start');

    if (contentItems.length > 0) {
      t.staggerFromTo(contentItems, 1, { autoAlpha: 0, y: 40 }, { autoAlpha: 1, y: 0 }, 0.2, 'start');
    }

    if (this.heroImage) {
      t.fromTo(this.heroImage, 1, { autoAlpha: 0 }, { autoAlpha: 1 }, 'start+=0.6');
    }

  }

  render() {
    const { title, heroImage, createdDate, category, author } = this.props;
    const IMAGE_WIDTH = 1060;

    return (
      <div className={s.articleIntro}>
        <header className={s.articleIntro__inner}>
          {heroImage &&
            <div className={s.articleIntro__imageWrap} ref={(el) => { this.heroImage = el; }}>
              <div className={s.articleIntro__image}>
                <Image
                  src={`${heroImage.src}?w=${IMAGE_WIDTH}`}
                  src2x={`${heroImage.src}?w=${IMAGE_WIDTH * 2}`}
                  alt={heroImage.alt}
                  width={heroImage.width}
                  height={heroImage.height}
                />
              </div>
            </div>
          }

          <div className={s.articleIntro__intro} ref={(el) => { this.intro = el; }}>
            <div className={s.articleIntro__subHeading}>
              {createdDate &&
                <span>{createdDate}</span>
              }
              {category &&
                <span> – {category}</span>
              }
            </div>
            <h1 className={s.articleIntro__heading}>{title}</h1>
            {author && <ArticleAuthor author={author}/>}
          </div>
        </header>
      </div>
    );
  }
}
